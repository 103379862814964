import baseEditGridForm from 'formiojs/components/editgrid/EditGrid.form';
import { ignoreBuilderFields } from '#web-components/utils';
import {
  defaultConditionalFields,
  defaultDataFields,
  defaultValidationFields,
  defaultApiFields,
} from '#web-components/components/Form/constants';

/**
 * This function returns the fields that should be present in the builder
 * You can extend the default one of any component
 * The values you pass are merged with the passed ones using the function unifyComponents (seacrh in formio.js source)
 *
 * *overrideEditForm* param is used when component key is equal to the default one to favor yours
 * *ignore* param will force the skipping of this component
 */
export default () => {
  const MAX_COUNT_ROW = 50;
  return baseEditGridForm([
    {
      key: 'display',
      components: [
        ...ignoreBuilderFields([
          'customClass',
          'openWhenEmpty',
          'conditionalAddButton',
          'autofocus',
          'tableView',
          'modalEdit',
          'hideLabel',
        ]),
        {
          type: 'checkbox',
          label: 'Multiple-record selection',
          key: 'multipleSelection',
          tooltip: '',
          weight: 1200,
          input: true,
        },
        {
          type: 'checkbox',
          label: 'Read Only',
          key: 'readOnly',
          tooltip: '',
          weight: 1200,
          input: true,
        },
        {
          overrideEditForm: true,
          type: 'checkbox',
          label: 'Disable Adding / Removing Rows',
          key: 'disableAddingRemovingRows',
          tooltip: 'Check if you want to hide Add Another button and Remove Row button',
          weight: 1300,
          input: true,
          clearOnHide: false,
          customConditional(context: { data: { readOnly?: boolean } }) {
            return !context.data.readOnly;
          },
        },
        {
          overrideEditForm: true,
          weight: 1400,
          type: 'checkbox',
          label: 'Disabled',
          tooltip: 'Disable the form input.',
          key: 'disabled',
          input: true,
          clearOnHide: false,
          customConditional(context: { data: { readOnly?: boolean } }) {
            return !context.data.readOnly;
          },
        },
        {
          type: 'checkbox',
          label: 'Quick Search',
          key: 'quickSearch',
          tooltip: 'Add simple search input to Edit grid table',
          weight: 1001,
          input: true,
          clearOnHide: false,
        },
      ],
    },
    {
      key: 'templates',
      components: [...ignoreBuilderFields([
        'templates.footer',
        'templates.header',
        'templates.row',
        'rowClass',
        'modal',
      ]),
      {
        key: 'removeRow',
        hidden: true,
        overrideEditForm: true,
      },
      {
        type: 'textfield',
        input: true,
        key: 'removeRowText',
        label: 'Remove Row Text',
        placeholder: 'Remove',
        tooltip: 'Set the text of the remove Row button.',
        customDefaultValue: 'value = data.removeRow && data.removeRow !== "Cancel" ? data.removeRow : ""',
      }],
    },
    {
      key: 'data',
      components: [
        ...ignoreBuilderFields([
          'persistent',
          'inlineEdit',
          'protected',
          'dbIndex',
          'encrypted',
          'calculateServer',
        ]),
        ...defaultDataFields,
        {
          weight: 1400,
          type: 'checkbox',
          label: 'Optimize column render',
          // eslint-disable-next-line max-len
          tooltip: 'This will greatly improve the performance of the EditGrid, however some advanced features such as calculated values will not be available for table view (they will still function as intended in single-item view, e.g. in a modal).',
          key: 'optimizedRendering',
          input: true,
        },
      ],
    },
    {
      key: 'validation',
      components: [
        ...ignoreBuilderFields([
          'rowDrafts',
          'json-validation-json',
          'unique',
          'errorLabel',
        ]),
        {
          key: 'validate.maxLength',
          defaultValue: MAX_COUNT_ROW,
          validate: {
            max: MAX_COUNT_ROW,
          },
          overrideEditForm: true,
        },
        ...defaultValidationFields,
      ],
    },
    {
      key: 'api',
      components: [
        ...ignoreBuilderFields(['tags', 'properties']),
        ...defaultApiFields,
      ],
    },
    {
      key: 'logic',
      components: [
        {
          key: 'logic',
          ignore: true,
        },
        {
          type: 'datagrid',
          input: true,
          label: 'Record Actions',
          key: 'rowActions',
          tooltip: 'Maximum number of actions - 5',
          weight: 10,
          tableView: false,
          reorder: true,
          defaultValue: [],
          initEmpty: true,
          validate: {
            required: false,
            minLength: '',
            maxLength: '5',
          },
          components: [
            {
              label: 'Label',
              key: 'label',
              input: true,
              type: 'textfield',
              tableView: false,
              validate: {
                required: true,
              },
            },
            {
              label: 'Action',
              key: 'code',
              input: true,
              type: 'textfield',
              tableView: false,
              validate: {
                required: true,
              },
            },
          ],
        },
      ],
    },
    {
      key: 'layout',
      ignore: true,
    },
    {
      key: 'conditional',
      components: defaultConditionalFields,
    },
  ]);
};
