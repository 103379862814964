import { ROUTES } from 'constants/routes';
import React from 'react';
import { NavLink as RouterNavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';

import useAuthentication from 'hooks/useAuthentication';
import { X_PATH } from 'constants/xPath';
import Link from '#web-components/components/Link';
import styles from './HeaderLinks.styles';

const useStyles = makeStyles(styles, { name: 'HeaderLinks' });

const HEADER_LINKS = [
  {
    title: 'components.header.home',
    path: ROUTES.HOME,
  },
  {
    title: 'appSections.myNotifications.title',
    path: ROUTES.NOTIFICATION_LIST,
  },
  {
    title: 'appSections.processDefinition.title',
    path: ROUTES.PROCESS_LIST,
  },
  {
    title: 'appSections.processActive.title',
    path: ROUTES.PROCESS_INSTANCE_LIST,
  },
  {
    title: 'appSections.task.title',
    path: ROUTES.USER_TASK_LIST,
  },
  {
    title: 'appSections.report.title',
    path: ROUTES.REPORTS_LIST,
  },
];

export default function HeaderLinks() {
  const authentication = useAuthentication();
  const { t } = useTranslation('common');
  const classes = useStyles();

  if (!(authentication.authenticated)) {
    return null;
  }

  return (
    <div className={classes.root} data-xpath={X_PATH.headerLinks}>
      {HEADER_LINKS.map((link) => (
        <Link
          key={link.path}
          component={RouterNavLink}
          to={link.path}
          activeClassName={classes.active}
          className={classes.link}
        >
          {t(link.title)}
        </Link>
      ))}
    </div>
  );
}
