import React from 'react';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import { makeStyles } from '@material-ui/core';

import { FileFullResponse, FormComponent } from '#web-components/components/Form/types';
import { ColumnDefinition, ListItem } from '#web-components/types/table';
import Link from '#web-components/components/Link';
import DownloadIcon from '#web-components/components/Icons/DownloadIcon';
import Typography from '#web-components/components/Typography';

import styles from './FileCell.styles';

interface FileCellProps {
  component: FormComponent;
  item: ListItem;
  columnDefinition: ColumnDefinition;
}

const useStyles = makeStyles(styles, { name: 'FileCell' });

export const FileCell: React.FC<FileCellProps> = ({ columnDefinition, component, item }: FileCellProps) => {
  const classes = useStyles();
  const value: Array<FileFullResponse> = get(item, columnDefinition.property, []);
  const valueArray = isArray(value) ? value : [];

  if (get(component, 'uploadOnly', false)) {
    return (
      <span>{valueArray.map((file) => file.name).join(', ')}</span>
    );
  }

  return (
    <>
      {valueArray.map((file) => (
        <div className={classes.linkContainer} key={file.url}>
          <Link
            download="true"
            to={file.url}
            className={classes.link}
          >
            <DownloadIcon className={classes.icon} />
            <Typography variant="h8" component="span" className={classes.text}>
              {file.name || get(file, 'originalName', '') }
            </Typography>
          </Link>
        </div>
      ))}
    </>
  );
};
