import { createStyles, Theme } from '@material-ui/core';
import get from 'lodash/get';
import omit from 'lodash/omit';

import { styleFormIoComponent } from '#web-components/styles/form';
import { h7 } from '#web-components/styles';
import { COMPONENT_CLASSES } from '../../constants';

const editGridSelector = `.${COMPONENT_CLASSES.editgrid}`;

export default ({ colors, spacing }: Theme) => {
  const actionsWidth = spacing(6);
  return createStyles({
    '@global': {
      [editGridSelector]: {
        ...get(styleFormIoComponent(COMPONENT_CLASSES.editgrid, colors), editGridSelector, {}),
        '& label.col-form-label': {
          ...h7,
          color: colors.textMainPrimary,
        },
      },
      ...omit(styleFormIoComponent(COMPONENT_CLASSES.editgrid, colors), editGridSelector),
    },
    root: {
      color: colors.textMainPrimary,
    },
    table: {
      marginBottom: '20px',
      paddingRight: actionsWidth,
    },
    tableInner: {
      borderBottom: `2px solid ${colors.uIBase}`,
    },
    cell: {
      height: spacing(9) + 2,
      boxSizing: 'border-box',
    },
    row: {
      borderBottom: `2px solid ${colors.uIBase}`,
    },
    selectedRow: {
      backgroundColor: colors.layoutBackgroundSecondary,
    },
    form: {
      paddingTop: spacing(6),
      width: '100%',
      maxWidth: spacing(80),
      '& .formio-component-submit': {
        display: 'inline-block',
        marginRight: spacing(3),
      },
      '& .formio-component-cancel': {
        display: 'inline-block',
      },
    },
    modal: {
      'max-width': spacing(100),
    },
    deleteModalButton: {
      marginRight: spacing(3),
      display: 'inline-block',
    },
    deleteConfirmationRoot: {
      width: spacing(80),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    deleteModalTitle: {
      marginBottom: spacing(6),
      marginTop: spacing(3),
    },
    actionsCell: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      border: 'none',
      position: 'absolute',
      right: 0,
      width: actionsWidth,
    },
    addButton: {
      border: 'none',
      backgroundColor: 'transparent',
    },
    selectionCheckboxLabel: {
      padding: 0,
      margin: 0,
    },
    selectionCheckboxRoot: {
      padding: 0,
      margin: 0,
    },
    searchInput: {
      marginTop: spacing(1.5),
    },
  });
};
