import {
  createAction, createSlice, PayloadAction,
} from '@reduxjs/toolkit';

import { SignTaskPayload, UserTask, TaskData } from '#shared/types/task';
import { ErrorInfo } from '#shared/types/common';
import { Form, FormSubmission } from '#web-components/components/Form/types';

export interface UserTaskState {
  data: UserTask | null;
  form: Form | null;
}

const initialState: UserTaskState = {
  data: null,
  form: null,
};

const ACTION_PREFIX = 'userTask';

export const getTaskRequest = createAction<string>(`${ACTION_PREFIX}/getTaskRequest`);
export const getTaskError = createAction<ErrorInfo>(`${ACTION_PREFIX}/getTaskError`);

export const completeTaskRequest = createAction<{
  taskId: string,
  taskName?: string,
  formData: FormSubmission,
  processInstanceId?: string,
}>(`${ACTION_PREFIX}/completeTaskRequest`);
export const completeTaskError = createAction<Array<ErrorInfo>>(`${ACTION_PREFIX}/completeTaskError`);
export const completeTaskClean = createAction(`${ACTION_PREFIX}/completeTaskClean`);

export const getFormError = createAction<ErrorInfo>(`${ACTION_PREFIX}/getFormError`);

export const signTaskRequest = createAction<{
  taskId: string,
  taskName?: string,
  data: SignTaskPayload,
  processInstanceId?: string,
}>(`${ACTION_PREFIX}/signTaskRequest`);

export const signTaskError = createAction<Array<ErrorInfo>>(`${ACTION_PREFIX}/signTaskError`);
export const signTaskSuccess = createAction(`${ACTION_PREFIX}/signTaskSuccess`);
export const signTaskClean = createAction(`${ACTION_PREFIX}/signTaskClean`);

export const getPendingTaskRequest = createAction<{
  processInstanceId: string }>(`${ACTION_PREFIX}/getPendingTaskRequest`);
export const getPendingTaskSuccess = createAction(`${ACTION_PREFIX}/getPendingTaskSuccess`);
export const getPendingTaskError = createAction<ErrorInfo>(`${ACTION_PREFIX}/getPendingTaskError`);

export const saveTaskRequest = createAction<
{ taskId: string, data: TaskData }>(`${ACTION_PREFIX}/saveTaskRequest`);
export const saveTaskError = createAction<ErrorInfo>(`${ACTION_PREFIX}/saveTaskError`);

const UserTaskSlice = createSlice({
  name: ACTION_PREFIX,
  initialState,
  reducers: {
    getTaskSuccess(state, action: PayloadAction<UserTask>) {
      return {
        ...state,
        data: action.payload,
      };
    },
    saveTaskSuccess(state, action: PayloadAction<TaskData>) {
      return {
        ...state,
        data: {
          ...(state.data ?? {}) as UserTask,
          data: action.payload,
        },
      };
    },
    getTaskClean(state) {
      return {
        ...state,
        data: null,
        form: null,
      };
    },
    getFormSuccess(state, action: PayloadAction<Form>) {
      return {
        ...state,
        form: action.payload,
      };
    },
    completeTaskSuccess(state) {
      return {
        ...state,
      };
    },
  },
  extraReducers: {
    [getTaskRequest.type]: (state) => {
      return {
        ...state,
        data: null,
        form: null,
      };
    },
    // we have to fill submission data for allowing to submit form multiply times
    // we need this in case of error while submitting form data (500 errors)
    // rawData is used because some changes made during prepareSubmissions are irreversible
    [completeTaskRequest.type]: (state, action: PayloadAction<{
      taskId: string,
      formData: FormSubmission,
      processInstanceId?: string,
    }>) => {
      return {
        ...state,
        data: {
          ...state.data,
          data: action.payload.formData.rawData,
        },
      } as UserTaskState;
    },
  },
});

export default UserTaskSlice.reducer;

export const {
  getTaskSuccess,
  getTaskClean,
  getFormSuccess,
  completeTaskSuccess,
  saveTaskSuccess,
} = UserTaskSlice.actions;
