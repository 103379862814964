import { Components, ReactComponent } from 'react-formio';
import { uniqueName } from 'formiojs/utils/utils';
import fileProcessor from 'formiojs/providers/processor/fileProcessor';

import { addUniqClasses } from '#web-components/utils';

import settingsForm from './CustomFormioFileSettings';
import { COMPONENT_CLASSES } from '../../constants';
import { modifySelectRowData } from '../../utils';

type FileStatus = {
  message?: string;
  name: string;
  originalName: string;
  size: number;
  status?: string;
  progress?: unknown;
};

const FileComponent = Components.components.file;

function download(uri: string, name: string, type: string) {
  const link = document.createElement('a');
  link.download = name;
  link.href = uri;
  link.type = type;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

class CustomFile extends FileComponent {
  static editForm = settingsForm;

  constructor(component: Record<string, unknown>, options: Record<string, unknown>, data: unknown) {
    super(component, options, data);
    this.component.customClass = addUniqClasses(
      COMPONENT_CLASSES.file,
      COMPONENT_CLASSES.bootstrapComponent,
      this.component.customClass,
    );
    this.component.storage = 'url';
  }

  public static schema() {
    return ReactComponent.schema({
      type: 'file',
      label: 'Upload',
      key: 'file',
      image: false,
      privateDownload: false,
      imageSize: '200',
      filePattern: '*',
      uploadOnly: false,
      fileMinSize: '0MB',
      fileMinTotalSize: '0MB',
    });
  }

  get defaultSchema() {
    return CustomFile.schema();
  }

  get emptyValue() {
    return [];
  }

  checkCondition(row: Record<string, unknown>, data: Record<string, unknown>) {
    return super.checkCondition(modifySelectRowData(this.component, this.root, row), data);
  }

  // eslint-disable-next-line consistent-return, @typescript-eslint/no-explicit-any
  getFile(fileInfo: any) {
    const { options = {} } = this.component;
    const { fileService } = this;
    if (!fileService) {
      // eslint-disable-next-line no-alert
      return alert('File Service not provided');
    }
    if (this.component.privateDownload) {
      // eslint-disable-next-line no-param-reassign
      fileInfo.private = true;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fileService.downloadFile(fileInfo, options).then((file: any) => {
      if (file) {
        // NEXT LINES ARE DIFFERENT FROM FORM.IO SOURCE
        // if (['base64', 'indexeddb'].includes(file.storage)) {
        download(
          fileInfo.url,
          fileInfo.originalName || fileInfo.name || fileInfo?.data?.name,
          fileInfo.type || fileInfo?.data?.type,
        );
        // }
        // else {
        //   window.open(file.url, '_blank');
        // }
        // END CHANGES THAT ARE DIFFERENT FROM FORM.IO SOURCE
      }
    })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((response: any) => {
        // Is alert the best way to do this?
        // User is expecting an immediate notification due to attempting to download a file.
        // eslint-disable-next-line no-alert
        alert(response);
      });
  }

  upload(files: FileList): void {
    // NEXT LINES ARE DIFFERENT FROM FORM.IO SOURCE
    const canUploadFiles = this.component.multiple || !this.getValue().length;
    if (!canUploadFiles) {
      return;
    }
    // END CHANGES THAT ARE DIFFERENT FROM FORM.IO SOURCE

    // Copy logic from parent method
    //  in order to edit nested callbacks
    // @see: https://github.com/formio/formio.js/blob/4.13.x/src/components/file/File.js#L600
    // Only allow one upload if not multiple.
    if (!this.component.multiple) {
      // eslint-disable-next-line no-param-reassign
      files = Array.prototype.slice.call(files, 0, 1) as unknown as FileList;
    }
    if (this.component.storage && files && files.length) {
      this.fileDropHidden = true;

      // files is not really an array and does not have a forEach method, so fake it.
      Array.prototype.forEach.call(files, async (file) => {
        const fileName = uniqueName(file.name, this.component.fileNameTemplate, this.evalContext());
        const fileUpload: FileStatus = {
          originalName: file.name,
          name: fileName,
          size: file.size,
          status: 'info',
          message: this.t('Processing file. Please wait...'),
        };

        // Check if file with the same name is being uploaded
        const fileWithSameNameUploaded = this.dataValue.some(
          (fileStatus: FileStatus) => fileStatus.originalName === file.name,
        );
        const fileWithSameNameUploadedWithError = this.statuses.findIndex(
          (fileStatus: FileStatus) => fileStatus.originalName === file.name
          && fileStatus.status === 'error',
        );

        if (fileWithSameNameUploaded) {
          fileUpload.status = 'error';
          fileUpload.message = this.t('File with the same name is already uploaded');
        }

        if (fileWithSameNameUploadedWithError !== -1) {
          this.statuses.splice(fileWithSameNameUploadedWithError, 1);
          this.redraw();
        }

        // Check file pattern
        if (this.component.filePattern && !this.validatePattern(file, this.component.filePattern)) {
          fileUpload.status = 'error';
          fileUpload.message = this.t('File is the wrong type; it must be {{ pattern }}', {
            pattern: this.component.filePattern,
          });
        }

        // Check file minimum size
        if (this.component.fileMinSize && !this.validateMinSize(file, this.component.fileMinSize)) {
          fileUpload.status = 'error';
          fileUpload.message = this.t('File is too small; it must be at least {{ size }}', {
            size: this.component.fileMinSize,
          });
        }

        // Check file maximum size
        if (this.component.fileMaxSize && !this.validateMaxSize(file, this.component.fileMaxSize)) {
          fileUpload.status = 'error';
          fileUpload.message = this.t('File is too big; it must be at most {{ size }}', {
            size: this.component.fileMaxSize,
          });
        }

        // NEXT LINES ARE DIFFERENT FROM FORM.IO SOURCE
        // Check file minimum total size
        if (this.component.fileMinTotalSize
          && !this.validateMinTotalSize(Array.from(files), this.component.fileMinTotalSize)) {
          fileUpload.status = 'error';
          fileUpload.message = this.t('Total file size is too small; it must be at least {{ size }}', {
            size: this.component.fileMinTotalSize,
          });
        }

        // Check file maximum total size
        if (this.component.fileMaxTotalSize
          && !this.validateMaxTotalSize(Array.from(files), this.component.fileMaxTotalSize)) {
          fileUpload.status = 'error';
          fileUpload.message = this.t('Total file size is too big; it must be at most {{ size }}', {
            size: this.component.fileMaxTotalSize,
          });
        }
        // END CHANGES THAT ARE DIFFERENT FROM FORM.IO SOURCE

        // Get a unique name for this file to keep file collisions from occurring.
        const dir = this.interpolate(this.component.dir || '');
        const { fileService } = this;
        if (!fileService) {
          fileUpload.status = 'error';
          fileUpload.message = this.t('File Service not provided.');
        }

        this.statuses.push(fileUpload);
        this.redraw();

        if (fileUpload.status !== 'error') {
          if (this.component.privateDownload) {
            // eslint-disable-next-line no-param-reassign
            file.private = true;
          }
          const { storage, options = {} } = this.component;
          const url = this.interpolate(this.component.url, { file: fileUpload });
          let groupKey = null;
          let groupPermissions = null;

          // Iterate through form components to find group resource if one exists
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          this.root.everyComponent((element: any) => {
            if (element.component?.submissionAccess || element.component?.defaultPermission) {
              groupPermissions = !element.component.submissionAccess ? [
                {
                  type: element.component.defaultPermission,
                  roles: [],
                },
              ] : element.component.submissionAccess;

              groupPermissions.forEach((permission: { type: string }) => {
                groupKey = ['admin', 'write', 'create'].includes(permission.type) ? element.component.key : null;
              });
            }
          });

          const fileKey = this.component.fileKey || 'file';
          // eslint-disable-next-line no-underscore-dangle
          const groupResourceId = groupKey ? this.currentForm.submission.data[groupKey]._id : null;
          let processedFile = null;

          if (this.root.options.fileProcessor) {
            try {
              if (this.refs.fileProcessingLoader) {
                this.refs.fileProcessingLoader.style.display = 'block';
              }
              const fileProcessorHandler = fileProcessor(this.fileService, this.root.options.fileProcessor);
              processedFile = await fileProcessorHandler(file, this.component.properties);
            } catch (err) {
              fileUpload.status = 'error';
              fileUpload.message = this.t('File processing has been failed.');
              this.fileDropHidden = false;
              this.redraw();
              return;
            } finally {
              if (this.refs.fileProcessingLoader) {
                this.refs.fileProcessingLoader.style.display = 'none';
              }
            }
          }

          fileUpload.message = this.t('Starting upload.');
          this.redraw();

          const filePromise = fileService.uploadFile(
            storage,
            processedFile || file,
            fileName,
            dir,
            // Progress callback
            (evt: ProgressEvent<EventTarget>) => {
              fileUpload.status = 'progress';
              // eslint-disable-next-line no-mixed-operators
              fileUpload.progress = +(100.0 * evt.loaded / evt.total);
              delete fileUpload.message;
              this.redraw();
            },
            url,
            options,
            fileKey,
            groupPermissions,
            groupResourceId,
            // Upload start callback
            () => {
              this.emit('fileUploadingStart', filePromise);
            },
            // Abort upload callback
            // eslint-disable-next-line no-return-assign
            (abort: unknown) => this.abortUpload = abort,
          ).then((fileInfo: { originalName: string }) => {
            const index = this.statuses.indexOf(fileUpload);
            if (index !== -1) {
              this.statuses.splice(index, 1);
            }
            // eslint-disable-next-line no-param-reassign
            fileInfo.originalName = file.name;
            if (!this.hasValue()) {
              this.dataValue = [];
            }
            this.dataValue.push(fileInfo);
            this.fileDropHidden = false;
            this.redraw();
            this.triggerChange();
            this.emit('fileUploadingEnd', filePromise);
          })
            .catch((response: string) => {
              fileUpload.status = 'error';
              fileUpload.message = response;
              delete fileUpload.progress;
              this.fileDropHidden = false;
              this.redraw();
              this.emit('fileUploadingEnd', filePromise);
            });
        }
      });
    }

    // NEXT LINES ARE DIFFERENT FROM FORM.IO SOURCE
    // Fix for clearing statuses on clearing file upload
    // Fix changes between 4.12.x and 4.13.x
    // @see: https://github.com/formio/formio.js/blob/4.12.x/src/components/file/File.js#L582
    // @see: https://github.com/formio/formio.js/blob/4.13.x/src/components/file/File.js#L605
    // @see: https://github.com/formio/formio.js/blob/4.12.x/src/components/file/File.js#L697
    // @see: https://github.com/formio/formio.js/blob/4.13.x/src/components/file/File.js#L744
    if (this.fileDropHidden && this.statuses?.some((upload: { status?: unknown }) => upload?.status === 'error')) {
      this.fileDropHidden = false;
      this.redraw();
    }
    // END CHANGES THAT ARE DIFFERENT FROM FORM.IO SOURCE
  }

  deleteFile(fileInfo: Record<string, unknown>) {
    this.setPristine(false);
    super.deleteFile(fileInfo);
  }

  clearOnHide(...args: unknown[]): void {
    super.clearOnHide(...args);

    if (this.component.clearOnHide && !this.visible) {
      this.refs?.fileStatusRemove?.forEach((elem: HTMLElement) => {
        elem.click?.();
      });
    }
  }

  protected get totalDataValueSize(): number {
    return this.dataValue?.reduce((total: number, { size }: { size: number }): number => total + size, 0) ?? 0;
  }

  validateMinTotalSize(files: Array<{ size: number }>, pattern: string): boolean {
    const filesTotal: number = files?.reduce((total, { size }) => total + size, 0) ?? 0;
    return this.totalDataValueSize + filesTotal + 0.1 >= this.translateScalars(pattern);
  }

  validateMaxTotalSize(files: Array<{ size: number }>, pattern: string): boolean {
    const filesTotal: number = files?.reduce((total, { size }) => total + size, 0) ?? 0;
    return this.totalDataValueSize + filesTotal - 0.1 <= this.translateScalars(pattern);
  }
}

export default CustomFile;
