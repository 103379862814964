import { ActionsObservable, StateObservable } from 'redux-observable';
import { Action } from '@reduxjs/toolkit';
import { of } from 'rxjs';
import {
  filter,
  map,
  mergeMap,
  concatMap,
} from 'rxjs/operators';

import { push } from 'connected-react-router';

import * as api from 'api/processDefinition';
import * as taskApi from 'api/taskManagement';
import * as formApi from 'api/formInteraction';
import { processDefinitionFormatter } from 'utils/processDefinition';
import { ROUTES, TAB_ROUTES } from 'constants/routes';
import {
  permissionNotificationErrorProps,
  processDefinitionFormNotFoundErrorProps,
  processDefinitionNotFoundErrorProps,
  tryAgainNotificationErrorProps,
} from 'constants/errorProps';
import { ErrorInfo, ERROR_TYPE } from '#shared/types/common';
import { ProcessDefinition } from '#shared/types/processDefinition';
import i18n from 'localization';
import { ActionCreator } from 'typesafe-actions';
import { formatFormSubmissionToTaskData, getValidationErrors } from '#shared/utils/formData';
import { RootState } from 'store/rootReducer';
import { ALL_TASKS_FILTER_PARAMS } from 'constants/tasks';
import {
  getCriticalErrorProps,
  isValidationError,
  catchError,
  isPermissionError,
  getNotificationErrorProps,
} from '#shared/utils/apiHelpers';
import {
  getProcessDefinitionsRequest,
  getProcessDefinitionsSuccess,
  getProcessDefinitionsCountRequest,
  getProcessDefinitionsCountSuccess,
  getProcessDefinitionsError,
  getProcessDefinitionsCountError,
  startProcessRequest,
  startProcessSuccess,
  startProcessError,
  getProcessDefinitionFormRequest,
  getProcessDefinitionFormError,
  getProcessDefinitionError,
  getProcessDefinitionSuccess,
  getProcessDefinitionFormSuccess,
  startProcessWithFormRequest,
  startProcessWithFormSuccess,
  startProcessWithFormError,
  getGroupedProcessDefinitionsRequest,
  getGroupedProcessDefinitionsError,
  getGroupedProcessDefinitionsSuccess,
} from './slice';
import { selectProcessDefinitionForm } from './selectors';

// helper for choose action after process is started
function processStartPipe(
  startResult: { ended: boolean, id: string },
  startProcessAction: ActionCreator,
  goToFirstTask?: boolean,
) {
  const getResultObservable = (route: string) => of(startProcessAction(), push(route, { forceLeave: true }));
  let resultRoute = ROUTES.PROCESS_INSTANCE_LIST;

  if (startResult.ended) {
    return getResultObservable(TAB_ROUTES.PROCESS_INSTANCE_LIST_ENDED);
  }

  return taskApi.getPendingTaskIdsList({ ...ALL_TASKS_FILTER_PARAMS, maxResults: 2 }, startResult.id).pipe(
    mergeMap(({ response: initiatedTasks }) => {
      if (initiatedTasks.length) {
        const taskId = initiatedTasks[0].id;
        const hasAssignee = initiatedTasks[0].assignee;
        if (hasAssignee) {
          resultRoute = ROUTES.USER_TASK.replace(':taskId', taskId);
        } else {
          resultRoute = ROUTES.USER_TASK_LIST;
        }
      }

      if (initiatedTasks.length > 1 && !goToFirstTask) {
        resultRoute = ROUTES.USER_TASK_LIST;
      }

      return getResultObservable(resultRoute);
    }),
    catchError(() => getResultObservable(resultRoute)),
  );
}

export const getProcessDefinitionsEpic = (
  action$: ActionsObservable<Action>,
) => {
  return action$
    .pipe(
      filter(getProcessDefinitionsRequest.match),
      mergeMap(() => {
        return api.getProcessListDefinitions().pipe(
          map((response) => {
            const formattedProcessDefinitionList = processDefinitionFormatter(response.response);
            return getProcessDefinitionsSuccess(formattedProcessDefinitionList);
          }),
          catchError((serverResponse) => of(
            getProcessDefinitionsError(getCriticalErrorProps({ serverResponse })),
          )),
        );
      }),
    );
};

export const getGroupedProcessDefinitionsEpic = (
  action$: ActionsObservable<Action>,
) => {
  return action$
    .pipe(
      filter(getGroupedProcessDefinitionsRequest.match),
      mergeMap(() => {
        return api.getGroupedProcessListDefinitions().pipe(
          map((response) => {
            return getGroupedProcessDefinitionsSuccess(response.response);
          }),
          catchError((serverResponse) => of(
            getGroupedProcessDefinitionsError(getCriticalErrorProps({ serverResponse })),
          )),
        );
      }),
    );
};

export const getProcessDefinitionsCountEpic = (
  action$: ActionsObservable<Action>,
) => {
  return action$
    .pipe(
      filter(getProcessDefinitionsCountRequest.match),
      mergeMap(() => {
        return api.getProcessDefinitionsCount().pipe(
          map((response) => {
            return getProcessDefinitionsCountSuccess(response.response.count);
          }),
          catchError(({ response }) => of(getProcessDefinitionsCountError({
            type: ERROR_TYPE.NOTIFICATION,
            traceId: response?.traceId,
            componentProps: {
              title: i18n.t('errors.notification.processDefinitionsCount.title'),
            },
          }))),
        );
      }),
    );
};

export const startProcessEpic = (
  action$: ActionsObservable<Action>,
) => {
  return action$
    .pipe(
      filter(startProcessRequest.match),
      mergeMap(({ payload }) => {
        const { processDefinitionKey, goToFirstTask } = payload;
        return api.startProcess(processDefinitionKey).pipe(
          mergeMap(({ response }) => processStartPipe(response, startProcessSuccess, goToFirstTask)),
          catchError(({ response }) => of(startProcessError(
            getNotificationErrorProps(response, tryAgainNotificationErrorProps),
          ))),
        );
      }),
    );
};

export const startProcessWithFormEpic = (
  action$: ActionsObservable<Action>,
  state$: StateObservable<RootState>,
) => {
  return action$
    .pipe(
      filter(startProcessWithFormRequest.match),
      mergeMap(({ payload }) => {
        return api.startProcessWithForm(payload.processDefinitionKey, formatFormSubmissionToTaskData(payload.formData))
          .pipe(
            mergeMap(({ response }) => {
              return processStartPipe(response, startProcessWithFormSuccess);
            }),
            catchError((response) => {
              const form = selectProcessDefinitionForm(state$.value);

              let errors: Array<ErrorInfo> = [];
              if (isValidationError(response)) {
                errors = getValidationErrors(i18n, response, form);
              }

              if (isPermissionError(response)) {
                errors = [getNotificationErrorProps(response.response, permissionNotificationErrorProps)];
              }

              return of(startProcessWithFormError(errors.length
                ? errors
                : [getNotificationErrorProps(response.response, tryAgainNotificationErrorProps)]));
            }),
          );
      }),
    );
};

export const getFormByProcessDefinitionKeyEpic = (action$: ActionsObservable<Action>) => {
  return action$.pipe(
    filter(getProcessDefinitionFormRequest.match),
    mergeMap(({ payload }) => {
      return api.getProcessDefinitionByKey(payload).pipe(
        concatMap(({ response: processResponse }) => formApi
          .getForm(processResponse.formKey)
          .pipe(
            mergeMap(({ response: formResponse }) => of(
              getProcessDefinitionSuccess(processResponse as unknown as ProcessDefinition),
              getProcessDefinitionFormSuccess(formResponse),
            )),
            catchError((serverResponse) => of(getProcessDefinitionFormError(
              getCriticalErrorProps({
                serverResponse,
                errorProps: processDefinitionFormNotFoundErrorProps,
              }),
            ))),
          )),
        catchError((serverResponse) => {
          if (serverResponse.status === 404) {
            return of(getProcessDefinitionError(getCriticalErrorProps({
              serverResponse,
              errorProps: processDefinitionNotFoundErrorProps,
            })));
          }
          return of(getProcessDefinitionError(
            getCriticalErrorProps({
              serverResponse,
              options: { link: ROUTES.PROCESS_LIST, title: i18n.t('startFormPage:backLinkCaption') },
            }),
          ));
        }),
      );
    }),
  );
};
