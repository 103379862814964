import React from 'react';
import {
  makeStyles,
} from '@material-ui/core';
import clsx from 'clsx';

import PopperButton from '#web-components/components/PopperButton';
import { ButtonType } from '#web-components/types/popper';
import MenuIcon from '#web-components/components/Icons/MenuIcon';
import MenuItem from '#web-components/components/MenuList/components/MenuItem';
import MenuList from '#web-components/components/MenuList';

import styles from './EditGridActions.styles';

type EditGridActionsProps = {
  additionalActions: Array<{ label: string, code: string }>;
  onAction: (code: string) => void;
  onDeleteClick: () => void,
  onEditClick: () => void,
  onViewClick: () => void,
  viewButtonText: string,
  deleteButtonText: string,
  editButtonText: string,
  editButtonDisabled?: boolean,
  deleteButtonDisabled?: boolean,
  readOnly?: boolean,
};

const useStyles = makeStyles(styles, { name: 'EditGridActions' });

export default function EditGridActions({
  onDeleteClick,
  onEditClick,
  onViewClick,
  deleteButtonText,
  editButtonText,
  viewButtonText,
  editButtonDisabled,
  deleteButtonDisabled,
  readOnly,
  additionalActions,
  onAction,
}: EditGridActionsProps) {
  const classes = useStyles();

  return (
    <PopperButton
      buttonType={ButtonType.icon}
      buttonProps={{
        children: <MenuIcon />,
      }}
      placement="bottom-end"
    >
      <MenuList className={classes.menuList}>
        {
          additionalActions.map((action) => (
            <MenuItem
              onClick={() => onAction(action.code)}
              className={classes.menuItem}
              key={action.code}
            >
              {action.label}
            </MenuItem>
          ))
        }
        {
          !!additionalActions.length && (
            <hr className={classes.line} />
          )
        }
        {
          readOnly && (
            <MenuItem
              onClick={onViewClick}
              className={classes.menuItem}
            >
              {viewButtonText}
            </MenuItem>
          )
        }
        {
          !readOnly && (
            <MenuItem
              onClick={onEditClick}
              className={classes.menuItem}
              disabled={editButtonDisabled}
            >
              {editButtonText}
            </MenuItem>
          )
        }
        {
          !readOnly && (
            <MenuItem
              onClick={onDeleteClick}
              className={clsx(classes.menuItem, classes.menuItemDelete)}
              disabled={deleteButtonDisabled}
            >
              {deleteButtonText}
            </MenuItem>
          )
        }
      </MenuList>
    </PopperButton>
  );
}
