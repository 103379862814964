import { combineEpics } from 'redux-observable';
import { Action } from '@reduxjs/toolkit';

import { RootState } from './rootReducer';
import {
  getProcessDefinitionsEpic,
  getProcessDefinitionsCountEpic,
  getGroupedProcessDefinitionsEpic,
  startProcessEpic,
  startProcessWithFormEpic,
  getFormByProcessDefinitionKeyEpic,
} from './processDefinitions';
import { getProcessInstancesEpic, getProcessInstancesCountEpic } from './processInstances';
import { getProcessInstancesHistoryEpic } from './processInstancesHistory';
import { getPendingTasksCountEpic, getUserTasksListEpic, claimUserTaskEpic } from './pendingTasks';
import {
  completeSignTaskEpic, completeTaskEpic, getFormByTaskIdEpic, saveTaskEpic, getPendingTaskEpic,
} from './userTask';
import { getCompetedTasksListEpic } from './completedTasks';
import { handleNonCriticalErrorsEpic } from './asyncAction';
import { getUserInfoEpic, userLoginEpic } from './currentUser';
import { onboardingProcessEpic } from './onboarding';
import {
  activateChannelEpic,
  deactivateChannelEpic,
  getUserSettingsEpic,
  validateChannelEpic,
  verifyChannelEpic,
} from './settings';
import { acknowledgeNotificationEpic, getNotificationListEpic } from './userNotifications';

export const rootEpic = combineEpics<Action, Action, RootState>(
  getProcessDefinitionsEpic,
  getProcessDefinitionsCountEpic,
  getFormByProcessDefinitionKeyEpic,
  getProcessInstancesEpic,
  getProcessInstancesCountEpic,
  getProcessInstancesHistoryEpic,
  getPendingTasksCountEpic,
  getUserTasksListEpic,
  startProcessEpic,
  startProcessWithFormEpic,
  getFormByTaskIdEpic,
  completeTaskEpic,
  getCompetedTasksListEpic,
  handleNonCriticalErrorsEpic,
  completeSignTaskEpic,
  userLoginEpic,
  getUserInfoEpic,
  claimUserTaskEpic,
  getGroupedProcessDefinitionsEpic,
  saveTaskEpic,
  onboardingProcessEpic,
  getPendingTaskEpic,
  activateChannelEpic,
  deactivateChannelEpic,
  getUserSettingsEpic,
  validateChannelEpic,
  verifyChannelEpic,
  getNotificationListEpic,
  acknowledgeNotificationEpic,
);
