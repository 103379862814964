import { ForwardedRef, MutableRefObject } from 'react';

export const assignMultipleRefs = (
  node: unknown,
  localRef: MutableRefObject<unknown>,
  forwardedRef: ForwardedRef<unknown>,
) => {
  // eslint-disable-next-line no-param-reassign
  localRef.current = node;
  if (typeof forwardedRef === 'function') {
    forwardedRef(node);
  } else if (forwardedRef) {
    // eslint-disable-next-line no-param-reassign
    forwardedRef.current = node;
  }
};
