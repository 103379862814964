import baseContentForm from 'formiojs/components/button/Button.form';
import { defaultConditionalFields, defaultApiFields } from '#web-components/components/Form/constants';
import { ignoreBuilderFields } from '#web-components/components/Form/utils';

export default () => {
  return baseContentForm([
    {
      key: 'display',
      components: [
        {
          key: 'action',
          data: {
            values: [
              { label: 'Submit', value: 'submit' },
              { label: 'Custom', value: 'custom' },
              { label: 'Navigation', value: 'navigation' },
            ],
          },
          overrideEditForm: true,
        },
        {
          type: 'textfield',
          key: 'actionCode',
          label: 'Action code',
          input: true,
          weight: 112,
          validate: {
            required: true,
          },
          conditional: {
            json: { '===': [{ var: 'data.action' }, 'navigation'] },
          },
        },
        {
          key: 'theme',
          data: {
            values: [
              { label: 'Primary', value: 'primary' },
              { label: 'Secondary', value: 'secondary' },
              { label: 'Cancel', value: 'cancel' },
            ],
          },
          overrideEditForm: true,
        },
        ...ignoreBuilderFields([
          'saveOnEnter',
          'size',
          'block',
          'leftIcon',
          'rightIcon',
          'shortcut',
          'customClass',
          'autofocus',
          'modalEdit',
        ]),
      ],
    },
    {
      key: 'conditional',
      components: defaultConditionalFields,
    },
    {
      key: 'api',
      components: [
        ...ignoreBuilderFields(['tags', 'properties']),
        ...defaultApiFields,
      ],
    },
    {
      key: 'logic',
      ignore: true,
    },
    {
      key: 'layout',
      ignore: true,
    },
    {
      key: 'confirmation',
      label: 'Confirmation',
      components: [
        {
          type: 'checkbox',
          key: 'confirmationEnable',
          label: 'Pop-up should Display',
          input: true,
        },
        {
          type: 'textarea',
          key: 'confirmationDescription',
          label: 'Description',
          input: true,
          placeholder: 'Description for this field.',
          editor: 'ace',
          wysiwyg: {
            minLines: 3,
            isUseWorkerDisabled: true,
          },
          validate: {
            maxLength: 256,
            required: false,
          },
          conditional: {
            json: {
              '===': [
                {
                  var: 'data.confirmationEnable',
                },
                true,
              ],
            },
          },
          // eslint-disable-next-line max-len
          tooltip: 'Поп-ап містить кнопки «Підтвердити» та «Скасувати». Будь ласка, формулюйте повідомлення з урахуванням, що кнопка &#34;Підтвердити&#34; виконає дію навігаційної кнопкі, тоді як кнопка &#34;Скасувати&#34; закриє поп-ап, і користувач залишиться на формі. Максимально дозволена кількість символів в повідомленні 256',
        },
      ],
    },
  ]);
};
