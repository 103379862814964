import { DateTime } from 'luxon';
import get from 'lodash/get';
import { ListItem } from '#web-components/types/table';

export const formatUTCDateTime = (dateTime: string) => {
  if (dateTime) {
    return DateTime.fromISO(dateTime, { zone: 'utc' }).toLocal().toFormat('dd.MM.yyyy HH:mm');
  }
  return '';
};

export const formatDateTimeToISO = (dateTime: string) => {
  return DateTime.fromISO(dateTime, { zone: 'utc' }).toISO();
};

export const getDateFromDateTime = (dateTime: string) => {
  return DateTime.fromISO(dateTime).toFormat('yyyy-MM-dd');
};

export const dateTimeFormatter = (listItem: ListItem, property: string) => {
  return formatUTCDateTime(get(listItem, property));
};

export const createTimeString = (seconds: number, settings: {
  locale: string,
  showMinutes?: boolean,
  showSeconds?: boolean,
}) => {
  return (
    `${settings.showMinutes && (seconds / 60) > 1
      ? Intl.NumberFormat(settings.locale, {
        style: 'unit',
        unit: 'minute',
        unitDisplay: 'long',
      }).format(Math.floor(seconds / 60))
      : ''
    } ${settings.showSeconds && seconds % 60 !== 0
      ? Intl.NumberFormat(settings.locale, {
        style: 'unit',
        unit: 'second',
        unitDisplay: 'long',
      }).format(Math.floor(seconds % 60))
      : ''
    }`
  );
};

export const timeConvert = (num: number) => {
  if (num < 0) {
    return '00:00';
  }
  const minutes = Math.floor(num / 60);
  const seconds = num % 60;
  return `${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
};
