import { Action } from 'redux';
import { ActionsObservable, StateObservable } from 'redux-observable';
import {
  concatMap, filter, mergeMap,
} from 'rxjs/operators';
import { of } from 'rxjs';
import { push } from 'connected-react-router';
import { ActionCreatorWithoutPayload } from '@reduxjs/toolkit';

import { notify, STATUSES } from 'reapop';
import * as api from 'api/taskManagement';
import * as formApi from 'api/formInteraction';
import * as documentsApi from 'api/documentsManagement';
import { ROUTES } from 'constants/routes';
import { formatFormSubmissionToTaskData, getValidationErrors } from '#shared/utils/formData';
import {
  conflictNotificationErrorProps,
  formNotFoundErrorProps,
  permissionNotificationErrorProps,
  tryAgainNotificationErrorProps,
} from 'constants/errorProps';
import i18n from 'localization';
import { ErrorInfo } from '#shared/types/common';
import { ALL_TASKS_FILTER_PARAMS } from 'constants/tasks';
import type { UserTaskCompleteResponse, UserTaskIdsResponse } from '#shared/types/task';
import { prepareFileSubmission, fillFilesMetadata, getFileIdsWithoutMetadata } from '#web-components/utils';
import { FileMetadata, MetadataSearchItem } from '#web-components/components/Form/types';
import {
  getCriticalErrorProps, isValidationError, catchError, isConflictError, isPermissionError, getNotificationErrorProps,
} from '#shared/utils/apiHelpers';

import type { RootState } from '../rootReducer';
import { selectUserTaskForm } from './selectors';

import {
  getTaskRequest,
  getTaskSuccess,
  getTaskError,
  getFormSuccess,
  completeTaskRequest,
  completeTaskError,
  completeTaskSuccess,
  signTaskRequest,
  signTaskError,
  signTaskSuccess,
  saveTaskRequest,
  saveTaskSuccess,
  saveTaskError,
  getPendingTaskRequest,
  getPendingTaskSuccess,
  getPendingTaskError,
} from './slice';

export const getFormByTaskIdEpic = (action$: ActionsObservable<Action>) => {
  return action$.pipe(
    filter(getTaskRequest.match),
    mergeMap(({ payload }) => {
      return api.getTaskById(payload).pipe(
        concatMap(({ response: taskResponse }) => formApi
          .getForm(taskResponse.formKey)
          .pipe(
            mergeMap(({ response: formResponse }) => {
              const fileIds = getFileIdsWithoutMetadata(formResponse, taskResponse.data);
              if (fileIds && fileIds.length > 0) {
                return documentsApi.getMetadataByIds(
                  fileIds as MetadataSearchItem[],
                  taskResponse.rootProcessInstanceId,
                  taskResponse.id,
                )
                  .pipe(
                    mergeMap(({ response: metadataResponse }) => {
                      const metadata = metadataResponse as FileMetadata[];
                      const data = fillFilesMetadata(metadata, taskResponse.data, formResponse);
                      const filledTask = {
                        ...taskResponse,
                        data,
                      };
                      return of(getTaskSuccess(filledTask), getFormSuccess(formResponse));
                    }),
                  );
              }
              return of(getTaskSuccess(taskResponse), getFormSuccess(formResponse));
            }),
            catchError((serverResponse) => of(getTaskError(getCriticalErrorProps({
              serverResponse,
              errorProps: formNotFoundErrorProps,
            })))),
          )),
        catchError((serverResponse) => of(getTaskError(
          getCriticalErrorProps({
            serverResponse,
            options: { link: ROUTES.USER_TASK_LIST, title: i18n.t('taskPage:backLinkCaption') },
          }),
        ))),
      );
    }),
  );
};

const getTaskSubmitSuccessActions = (
  {
    rootProcessInstanceId,
    taskName,
    successAction,
  }: { rootProcessInstanceId: string, taskName: string, successAction: ActionCreatorWithoutPayload },
) => {
  const getResultObservable = (route: string, isSuccessAction = true) => {
    if (isSuccessAction) {
      return of(
        successAction(),
        push(route, { forceLeave: true }),
        notify(
          i18n.t('taskPage:notification.success.message', { taskName }),
          STATUSES.success,
          {
            title: i18n.t('taskPage:notification.success.title'),
          },
        ),
      );
    }
    return of(
      successAction(),
      push(route, { forceLeave: true }),
    );
  };

  return api.getPendingTaskIdsList({ ...ALL_TASKS_FILTER_PARAMS, maxResults: 2 }, rootProcessInstanceId).pipe(
    mergeMap(({ response: newTasks } : { response: UserTaskIdsResponse[] }) => {
      let route = ROUTES.USER_TASK_LIST;

      if (newTasks.length === 1) {
        const hasAssignee = newTasks[0].assignee;
        const taskId = newTasks[0].id;
        route = hasAssignee ? ROUTES.USER_TASK.replace(':taskId', taskId) : route;
      }

      return getResultObservable(route);
    }),
  );
};

export const completeTaskEpic = (
  action$: ActionsObservable<Action>,
  state$: StateObservable<RootState>,
) => {
  return action$
    .pipe(
      filter(completeTaskRequest.match),
      mergeMap(({ payload }) => {
        const form = selectUserTaskForm(state$.value);
        const preparedFormData = prepareFileSubmission(form?.components || [], payload.formData);
        return api.completeTask(payload.taskId, formatFormSubmissionToTaskData(preparedFormData)).pipe(
          mergeMap(({ response } : { response: UserTaskCompleteResponse }) => {
            const { rootProcessInstanceId } = response;
            const { taskName = '' } = payload;
            return getTaskSubmitSuccessActions({ rootProcessInstanceId, taskName, successAction: completeTaskSuccess });
          }),
          catchError((response) => {
            let errors: Array<ErrorInfo> = [];
            if (isValidationError(response)) {
              errors = getValidationErrors(i18n, response, form);
            }

            if (isConflictError(response)) {
              errors = [getNotificationErrorProps(response.response, conflictNotificationErrorProps)];
            }

            if (isPermissionError(response)) {
              errors = [getNotificationErrorProps(response.response, permissionNotificationErrorProps)];
            }

            return of(completeTaskError(errors.length
              ? errors
              : [getNotificationErrorProps(response.response, tryAgainNotificationErrorProps)]));
          }),
        );
      }),
    );
};

export const completeSignTaskEpic = (
  action$: ActionsObservable<Action>,
  state$: StateObservable<RootState>,
) => {
  return action$
    .pipe(
      filter(signTaskRequest.match),
      mergeMap(({ payload }) => {
        return api.completeSignTask(payload.taskId, payload.data).pipe(
          mergeMap(({ response } : { response: UserTaskCompleteResponse }) => {
            const { rootProcessInstanceId } = response;
            const { taskName = '' } = payload;
            return getTaskSubmitSuccessActions({ rootProcessInstanceId, taskName, successAction: signTaskSuccess });
          }),
          catchError((response) => {
            const form = selectUserTaskForm(state$.value);
            let errors: Array<ErrorInfo> = [];
            if (isValidationError(response)) {
              errors = getValidationErrors(i18n, response, form);
            }

            if (isConflictError(response)) {
              errors = [getNotificationErrorProps(response.response, conflictNotificationErrorProps)];
            }

            if (isPermissionError(response)) {
              errors = [getNotificationErrorProps(response.response, permissionNotificationErrorProps)];
            }

            return of(signTaskError(errors.length
              ? errors
              : [getNotificationErrorProps(response.response, tryAgainNotificationErrorProps)]));
          }),
        );
      }),
    );
};

export const saveTaskEpic = (
  action$: ActionsObservable<Action>,
  state$: StateObservable<RootState>,
) => {
  return action$
    .pipe(
      filter(saveTaskRequest.match),
      mergeMap(({ payload }) => {
        const form = selectUserTaskForm(state$.value);
        const preparedFormData = prepareFileSubmission(form?.components || [], { data: payload.data });
        return api.saveTask(payload.taskId, formatFormSubmissionToTaskData(preparedFormData)).pipe(
          mergeMap(() => {
            return of(
              saveTaskSuccess(payload.data),
              notify(i18n.t('formMessages.dataSavedSuccessfully'), STATUSES.success),
            );
          }),
          catchError(({ response }) => {
            return of(saveTaskError(
              getNotificationErrorProps(response, tryAgainNotificationErrorProps),
            ));
          }),
        );
      }),
    );
};

export const getPendingTaskEpic = (action$: ActionsObservable<Action>) => {
  return action$.pipe(
    filter(getPendingTaskRequest.match),
    mergeMap(({ payload }) => {
      const { processInstanceId } = payload;
      return api.getPendingTaskIdsList({ ...ALL_TASKS_FILTER_PARAMS, maxResults: 1 }, processInstanceId).pipe(
        mergeMap(({ response: newTasks }) => {
          const { id } = newTasks[0];
          const route = ROUTES.USER_TASK.replace(':taskId', id);
          return of(getPendingTaskSuccess(), push(route));
        }),
        catchError((serverResponse) => of(getPendingTaskError(getCriticalErrorProps({ serverResponse })))),
      );
    }),
  );
};
