import React from 'react';
import clsx from 'clsx';
import { WithStyles } from '@material-ui/core';

import SignatureService, { EndUser } from '#web-components/services/signature';
import { ReadKeyResponse } from '#web-components/types/signature';
import Button, { ButtonVariants } from '#web-components/components/Button';
import Typography from '#web-components/components/Typography';
import { ButtonComponent } from '#web-components/components/Form/types';
import { Subject, firstValueFrom } from 'rxjs';
import styles from './SignatureWidget.styles';
import ButtonGroup from './components/ButtonGroup';
import QRCodeBlock, { QRCodeProps } from './components/QRCode';
import Error from './components/Error';

const SIGN_WIDGET_PARENT_ID = 'sign-widget-parent';
const SIGN_WIDGET_ID = 'sign-widget';

export interface SignatureWidgetProps {
  onLoadingChange?: (isLoading: boolean) => void;
  onSignText: (text: string) => void;
  onSignKeyReady?: (status: boolean) => void;
  onNavigation?: (actionCode: string) => void;
  dataToSign: Record<string, unknown> | string;
  xpathConfig?: {
    changeKeyButton?: string,
    signButton?: string,
  };
  changeKeyButtonCaption: string;
  signButtonCaption: string;
  signDataTitle: string;
  readKeyTitle: string;
  organizationCaption: string;
  rnokppCaption: string;
  edrpouCaption: string;
  signWidgetUri: string;
  // eslint-disable-next-line react/no-unused-prop-types
  signWidgetHeight?: number;
  signWidgetParentId?: string;
  signWidgetId?: string;
  navigationButton?: ButtonComponent[];
  errorButtonCaption?: string;
  qrCode?: Pick<QRCodeProps, 'title' | 'description' | 'timerText'> & { errorMessage: string }
}

export interface Props extends SignatureWidgetProps, WithStyles<typeof styles> { }

type State = Pick<QRCodeProps, 'url' | 'qrCode'> & {
  keyInfo: ReadKeyResponse[] | null,
  isSignKeyReady: boolean,
  error: string,
};

export default class SignatureWidget extends React.Component<Props, State> {
  state = {
    isSignKeyReady: false,
    keyInfo: null,
    url: '',
    qrCode: '',
    error: '',
  };

  timeout$: Subject<unknown> = new Subject();

  setLoading = this.props.onLoadingChange || (() => undefined);

  onSignKeyReady = this.props.onSignKeyReady || (() => undefined);

  onNavigation = this.props.onNavigation || (() => undefined);

  signLib: SignatureService | null = null;

  iframe: HTMLElement | null = null;

  componentDidMount() {
    const { signWidgetUri, signWidgetParentId = SIGN_WIDGET_PARENT_ID, signWidgetId = SIGN_WIDGET_ID } = this.props;
    this.setLoading(true);
    const instance = new SignatureService(signWidgetParentId, signWidgetUri, signWidgetId);

    this.iframe = document.getElementById(instance.SIGN_WIDGET_ID);
    this.iframe?.addEventListener('load', this.onLoadIframe);
    this.iframe?.setAttribute('sandbox', '');
    this.signLib = instance;
  }

  componentWillUnmount() {
    this.iframe?.removeEventListener('load', this.onLoadIframe);
  }

  onLoadIframe = () => {
    this.signLib
      ?.readPrivateKey()
      .then((data) => {
        this.handleChange('keyInfo')(data);
        this.handleChange('isSignKeyReady')(true);
        this.onSignKeyReady(true);
        return this.signLib?.addEventListener(
          EndUser.EventType.ConfirmKSPOperation.toString(),
          this.onConfirmKSPOperation,
        );
      })
      .catch((e) => {
        this.handleChange('isSignKeyReady')(false);
        this.onSignKeyReady(false);
        this.handleChange('error')(e.message);
      });
    this.setLoading(false);
  };

  handleResetKey = () => {
    this.handleChange('isSignKeyReady')(false);
    this.handleChange('keyInfo')(null);
    this.handleChange('error')(null);
    this.timeout$ = new Subject();
    this.signLib
      ?.resetKey()
      .then(() => {
        return this.signLib?.readPrivateKey();
      })
      .then((data) => {
        this.handleChange('keyInfo')(data || null);
        this.handleChange('isSignKeyReady')(true);
        this.handleChange('qrCode')(null);
      })
      .catch((e) => {
        this.handleChange('isSignKeyReady')(false);
        this.handleChange('error')(e.message);
      });
  };

  handleSignText = () => {
    const dataToSign = typeof (this.props.dataToSign) === 'string'
      ? this.props.dataToSign : JSON.stringify(this.props.dataToSign);

    const { url, qrCode: QRCode } = this.state;
    const showLoader = !(url && QRCode);
    this.setLoading(showLoader);

    Promise.race([this.signLib?.signData(dataToSign), firstValueFrom(this.timeout$)])
      .then((sign) => {
        this.props.onSignText(sign);
      })
      .catch((e) => {
        this.handleChange('error')(e.message);
      })
      .finally(() => {
        this.handleChange('qrCode')(null);
        this.setLoading(false);
      });
  };

  handleChange = (name: keyof State) => (value: boolean | ReadKeyResponse[] | null | string) => {
    this.setState({
      [name]: value,
    } as unknown as State);
  };

  onConfirmKSPOperation = (event: Pick<QRCodeProps, 'url' | 'qrCode'>) => {
    this.handleChange('url')(event.url);
    this.handleChange('qrCode')(event.qrCode);
    this.setLoading(false);
  };

  handleTime = (time: number) => {
    if (time === 0) {
      this.timeout$.error({ message: this.props.qrCode?.errorMessage });
    }
  };

  render() {
    const {
      classes,
      xpathConfig,
      signButtonCaption,
      changeKeyButtonCaption,
      errorButtonCaption = 'back',
      signDataTitle,
      readKeyTitle,
      organizationCaption,
      rnokppCaption,
      edrpouCaption,
      navigationButton,
      qrCode: qrCodeProps,
    } = this.props;
    const {
      isSignKeyReady, keyInfo: keyInfoUnknown, url, qrCode: QRCode, error,
    } = this.state;
    const keyInfo = keyInfoUnknown as ReadKeyResponse[] | null;
    const showQRCode = url && QRCode && qrCodeProps && keyInfo && !error;
    const showKeyInfo = isSignKeyReady && keyInfo && !showQRCode && !error;
    const hideWidget = isSignKeyReady || error;

    return (
      <div>
        <Typography variant="h2" className={classes.title}>
          {isSignKeyReady ? signDataTitle : readKeyTitle}
        </Typography>
        <div
          id={SIGN_WIDGET_PARENT_ID}
          className={clsx(classes.frameContainer, hideWidget && classes.hide)}
        />
        {error && (
          <Error
            message={error}
            buttonText={errorButtonCaption}
            onClick={this.handleResetKey}
          />
        )}
        {showQRCode && (
          <QRCodeBlock
            url={url}
            qrCode={QRCode}
            title={qrCodeProps?.title}
            description={qrCodeProps?.description}
            timerText={qrCodeProps?.timerText}
            onTime={this.handleTime}
          />
        )}
        {showKeyInfo && (
          <>
            <div className={classes.keyInfo}>
              <Typography
                variant="h5"
                className={classes.name}
              >
                {keyInfo[0].infoEx.subjFullName}
              </Typography>
              <Typography variant="tinyText" className={classes.caption}>
                {organizationCaption}
              </Typography>
              <Typography
                variant="bodyText"
                className={classes.gutterBottom}
              >
                {keyInfo[0].infoEx.subjOrg}
              </Typography>
              <Typography variant="tinyText" className={classes.caption}>
                {rnokppCaption}
              </Typography>
              <Typography
                variant="bodyText"
                className={classes.gutterBottom}
              >
                {keyInfo[0].infoEx.subjDRFOCode}
              </Typography>
              {keyInfo[0].infoEx.subjEDRPOUCode && (
                <>
                  <Typography variant="tinyText" className={classes.caption}>{edrpouCaption}</Typography>
                  <Typography variant="bodyText">
                    {keyInfo[0].infoEx.subjEDRPOUCode}
                  </Typography>
                </>
              )}
            </div>
            <div className={classes.buttonContainer}>
              <Button
                onClick={this.handleResetKey}
                className={classes.button}
                variant={ButtonVariants.secondary}
                data-xpath={xpathConfig?.changeKeyButton}
              >
                {changeKeyButtonCaption}
              </Button>
              <Button onClick={this.handleSignText} className={classes.button} data-xpath={xpathConfig?.signButton}>
                {signButtonCaption}
              </Button>
            </div>
          </>
        )}
        {!!navigationButton && (
          <ButtonGroup
            buttons={navigationButton}
            onClick={this.onNavigation}
          />
        )}
      </div>
    );
  }
}
