import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import mapValues from 'lodash/mapValues';
import flatten from 'lodash/flatten';
import values from 'lodash/values';
import flatMap from 'lodash/flatMap';
import get from 'lodash/get';
import type {
  FileFullResponse,
  FileShortResponse,
  Form,
  FileMetadata,
  TaskData,
} from '#web-components/components/Form/types';
import { isFileComponent } from '#web-components/components/Form/utils';
import { MetadataSearchItem } from '#web-components/components/Form/types';

export function getFormFileKeys(form: Form): string[] {
  return form.components
    .filter((component) => isFileComponent(component.type))
    .map((component) => component.key)
    .concat(flatMap(
      form.components
        .filter((component) => !!get(component, 'components')),
      (component) => getFormFileKeys(component as unknown as Form),
    ));
}

export function findDataByKey(
  data: TaskData,
  key: string,
): Array<{ key: string, data: unknown }> {
  if (isObject(data[key])) {
    return [{ key, data: data[key] as TaskData }];
  }

  const nestedValues = flatten(
    values(data)
      .filter((value: unknown) => isArray(value)),
  ) as Array<TaskData>;

  return flatten(nestedValues.map((value) => findDataByKey(value, key)));
}

export function getFileIdsWithoutMetadata(
  form: Form,
  data: TaskData<Record<string, Partial<FileFullResponse & MetadataSearchItem>[]>>,
) {
  if (!data) {
    return null;
  }
  const fileKeys = getFormFileKeys(form);

  if (fileKeys.length === 0) {
    return null;
  }

  const allFileFieldsData = flatten(
    fileKeys.map((key) => findDataByKey(data, key)),
  ) as Array<{ key: string, data: Partial<FileFullResponse & MetadataSearchItem>[] }>;
  const result: Partial<FileFullResponse & MetadataSearchItem>[] = [];
  allFileFieldsData
    .forEach((value) => {
      (value.data as FileShortResponse[]).forEach((fieldData) => {
        result.push({
          id: fieldData?.id || '',
          fieldName: value.key,
        });
      });
    });

  return result;
}

export function fillFilesMetadata(
  metadata: FileMetadata[],
  taskData: TaskData<Record<string, FileShortResponse | unknown>>,
  form: Form,
): TaskData<Record<string, FileFullResponse | unknown | unknown[]>> {
  return mapValues(taskData, (value, key) => {
    const isFileValue = getFormFileKeys(form).includes(key);
    const isEmptyValue = isArray(value) && value.length === 0;

    if (isArray(value) && !isFileValue) {
      return (value as Array<TaskData<Record<string, FileShortResponse | unknown>>>)
        .map((item) => fillFilesMetadata(metadata, item, form));
    }
    if (!isFileValue || isEmptyValue) {
      return value;
    }

    const metadataElements = metadata
      .filter((entry) => value && (value as FileShortResponse[]).some(({ id }) => id === entry.id)) as FileMetadata[];

    if (!metadataElements.length) {
      return value;
    }

    return metadataElements.map((metadataElement) => ({
      size: metadataElement.size,
      url: metadataElement.url,
      name: metadataElement.name,
      originalName: metadataElement.name,
      data: metadataElement,
    }));
  });
}
