/* eslint-disable no-underscore-dangle */
import baseSelectForm from 'formiojs/components/select/Select.form';
import formioUtils from 'formiojs/components/_classes/component/editForm/utils';
import { ignoreBuilderFields } from '#web-components/utils';
import {
  defaultConditionalFields,
  defaultDataFields,
  defaultValidationFields,
  defaultApiFields,
  tableSettingsTab,
} from '#web-components/components/Form/constants';

export default () => {
  return baseSelectForm([
    {
      key: 'display',
      components: [
        ...ignoreBuilderFields(
          [
            'widget',
            'customClass',
            'autofocus',
            'uniqueOptions',
            'modalEdit',
            'tableView',
            'hideLabel',
          ],
        ),
      ],
    },
    {
      key: 'data',
      components: [
        {
          type: 'checkbox',
          input: true,
          label: 'Add authentication cookies for cross-site requests',
          key: 'withCredentials',
          // eslint-disable-next-line max-len
          tooltip: 'With this setting cookies are added for cross-site requests (for same-site cookies are added anyway). You may need to turn this setting off if API does not support cookies for cross-site requests (e.g. has \'*\' in Access-Control-Allow-Origin response header)',
          weight: 11,
          defaultValue: false,
          conditional: {
            json: {
              and: [
                {
                  in: [
                    { var: 'data.dataSrc' },
                    [
                      'url',
                    ],
                  ],
                },
              ],
            },
          },
        },
        ...defaultDataFields,
        ...ignoreBuilderFields([
          'dataType',
          'sort',
          'searchEnabled',
          'selectThreshold',
          'readOnlyValue',
          'customOptions',
          'useExactSearch',
          'persistent',
          'protected',
          'dbIndex',
          'encrypted',
          'calculateServer',
          'authenticate',
          'ignoreCache',
        ]),
        {
          overrideEditForm: true,
          type: 'select',
          input: true,
          weight: 0,
          tooltip: 'The source toto retrieve the JSON data from.',
          key: 'dataSrc',
          defaultValue: 'values',
          label: 'Data Source Type',
          dataSrc: 'values',
          data: {
            values: [
              { label: 'Values', value: 'values' },
              { label: 'URL', value: 'url' },
              { label: 'Custom', value: 'custom' },
            ],
          },
        },
        {
          overrideEditForm: true,
          type: 'textfield',
          input: true,
          key: 'filter',
          label: 'Filter Query',
          weight: 17.5,
          description: 'The filter query for results.',
          tooltip: 'Use this to provide additional filtering using query parameters.',
          conditional: {
            json: {
              in: [{
                var: 'data.dataSrc',
              }, ['url', 'resource']],
            },
          },
        },
        {
          ...formioUtils.javaScriptValue(
            'Custom Filter',
            'customFilter',
            'customFilter',
            17.75,
            '<p>'
            + '<h4>Example:</h4>'
            + '<pre>show = +option.date.split(\'-\')[2] % 2;</pre>'
            + '</p>'
            + '<p>'
            + 'The result of the <code>show</code> is considered as boolean value and used for filtering of the items'
            + '</p>',
            '<p>TODO: logic example</p>',
            '<tr><th>show</th><td>The boolean value of availability of the current item</td></tr>'
            + '<tr><th>option</th><td>The current option to check availability</td></tr>'
            + '<tr><th>options</th><td>List of all options from the server</td></tr>',
          ),
          tooltip: 'The Custom Filter for filtering the ajax-loaded items',
          conditional: {
            json: {
              in: [{
                var: 'data.dataSrc',
              }, ['url']],
            },
          },
        },
        {
          overrideEditForm: true,
          type: 'select',
          multiple: true,
          input: true,
          key: 'refreshOn',
          label: 'Refresh Options On',
          weight: 19,
          tooltip: 'Refresh data when another field changes.',
          dataSrc: 'custom',
          valueProperty: 'value',
          data: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            custom(context: any) {
              const values: { label: string; value: string; }[] = [];
              context.utils.eachComponent(
                context.instance.options.editForm.components,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (component: any, path: string) => {
                  if (component.key !== context.data.key) {
                    values.push({
                      label: component.label || component.key,
                      value: path,
                    });
                  }
                },
              );
              return values;
            },
          },
          conditional: {
            json: {
              in: [
                { var: 'data.dataSrc' },
                [
                  'url',
                  'resource',
                  'values',
                  'custom',
                ],
              ],
            },
          },
        },
      ],
    },
    {
      key: 'validation',
      components: [
        ...defaultValidationFields,
        ...ignoreBuilderFields(['unique', 'errorLabel', 'validate.onlyAvailableItems']),
      ],
    },
    {
      key: 'api',
      components: [
        ...ignoreBuilderFields(['tags', 'properties']),
        ...defaultApiFields,
      ],
    },
    {
      key: 'logic',
      components: [],
      ignore: true,
    },
    {
      key: 'conditional',
      components: defaultConditionalFields,
    },
    tableSettingsTab,
  ]);
};
