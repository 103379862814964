import React from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core';
import Container from '#web-components/components/Container';
import Typography from '#web-components/components/Typography';
import Loader from '#web-components/components/Loader';
import RegistryName from '#web-components/components/Layouts/components/RegistryName';
import styles from './StandardLayout.styles';

export interface StandardLayoutProps {
  navbar: React.ReactNode
  children?: React.ReactNode;
  title?: string;
  registryName?: string;
  registryFullName?: string;
  description?: string;
  isLoading?: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  disableBackground?: boolean; // TODO: remove prop
  disableTitle?: boolean;
  loaderDescription?: string;
}

const useStyles = makeStyles(styles, { name: 'StandardLayout' });

export default function StandardLayout(props: StandardLayoutProps) {
  const {
    title,
    registryName,
    registryFullName,
    description,
    navbar,
    isLoading = false,
    children,
    disableTitle,
    loaderDescription,
  } = props;
  const classes = useStyles(props);
  const isVisibleRegistryName = registryName && registryFullName;

  return (
    <Container classes={{ root: classes.container }}>
      <Loader show={isLoading} description={loaderDescription} data-xpath="component-loader" />
      <Box className={classes.header}>
        {isVisibleRegistryName && (
          <RegistryName name={registryName} fullName={registryFullName} />
        )}
        {navbar}
        {!disableTitle && (
          <Grid container className={classes.wrap}>
            <Grid item xs={8}>
              <Box className={classes.title}>
                {title && <Typography variant="h1">{title}</Typography>}
                {description
                  && <Typography variant="bodyText" className={classes.description}>{description}</Typography>}
              </Box>
            </Grid>
          </Grid>
        )}
      </Box>
      <Box className={classes.contentWrap}>{children}</Box>
    </Container>
  );
}
