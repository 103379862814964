/* eslint-disable max-len */
import baseDateTimeForm from 'formiojs/components/datetime/DateTime.form';
import formioUtils from 'formiojs/components/_classes/component/editForm/utils';

import { ignoreBuilderFields } from '#web-components/utils';
import {
  defaultConditionalFields,
  defaultValidationFields,
  defaultDataFields,
  tableSettingsTab,
  defaultApiFields,
} from '#web-components/components/Form/constants';

export default () => {
  return baseDateTimeForm([
    {
      key: 'display',
      components: ignoreBuilderFields([
        'displayInTimezone',
        'useLocaleSettings',
        'shortcutButtons',
        'widget.type',
        'widget',
        'customClass',
        'autofocus',
        'modalEdit',
        'hideLabel',
        'tableView',
      ]),
    },
    {
      key: 'date',
      overrideEditForm: true,
      components: [
        {
          type: 'checkbox',
          input: true,
          key: 'enableDate',
          label: 'Enable Date Input',
          weight: 0,
          tooltip: 'Enables date input for this field.',
        },
        {
          type: 'datetime',
          input: true,
          key: 'datePicker.minDate',
          label: 'Use calendar to set minDate',
          weight: 10,
          tooltip: 'Enables to use calendar to set date.',
        },
        {
          type: 'datetime',
          input: true,
          key: 'datePicker.maxDate',
          label: 'Use calendar to set maxDate',
          weight: 20,
          tooltip: 'Enables to use calendar to set date.',
        },
        {
          type: 'tags',
          input: true,
          key: 'datePicker.disable',
          label: 'Disable specific dates or dates by range',
          placeholder: '(yyyy-MM-dd) or (yyyy-MM-dd - yyyy-MM-dd)',
          tooltip: 'Add dates that you want to blacklist. For example: \n \n 2025-02-21',
          validate: {
            custom: 'if (_.isEmpty(input)) {\n  return true;\n}\nconst dates = _.isArray(input) ?\ninput : input.split(component.delimeter);\nconst isValid = _.every(dates, (data) => \n  !!data.match(/\\d{4}-\\d{2}-\\d{2}/g));\nvalid = isValid || \'Invalid date\';',
          },
          weight: 21,
        },
        {
          type: 'panel',
          title: 'Custom Disabled Dates',
          collapsible: true,
          collapsed: true,
          style: { 'margin-bottom': '10px' },
          key: 'panel-disable-function',
          weight: 22,
          components: [
            formioUtils.logicVariablesTable('<tr><th>date</th><td>Plain JS date object. Convert to moment for better usability with <b>moment(date)</b></td></tr>'),
            {
              type: 'textarea',
              input: true,
              editor: 'ace',
              key: 'datePicker.disableFunction',
              label: 'Disabling dates by a function',
              description: `Commonly used methods:
              <ul>
              <li><b>moment(date)</b> - convert date object to moment object</li>
              <li><b>moment()</b> - moment object for current moment in time</li>
              <li><b>moment().isBefore(moment(), 'day')</b> - used to compare dates. See more methods in <a href="https://momentjs.com/docs/#/query/" target="_blank">momentjs docs</a></li>
              </ul>
              `,
            },
            {
              type: 'htmlelement',
              tag: 'div',
              content: `<h4>Example usage</h4>
                        <pre>// Disable all weekends
date.getDay() === 0 || date.getDay() === 6</pre>
                        <pre>// Disable dates after today (including)
moment(date).isSameOrAfter(moment(), 'day')</pre>
                `,
            },
          ],
        },
        {
          type: 'checkbox',
          input: true,
          key: 'datePicker.disableWeekends',
          label: 'Disable weekends',
          tooltip: 'Check to disable weekends',
          weight: 23,
        },
        {
          type: 'checkbox',
          input: true,
          key: 'datePicker.disableWeekdays',
          label: 'Disable weekdays',
          tooltip: 'Check to disable weekdays',
          weight: 23,
        },
      ],
    },
    {
      key: 'data',
      components: [
        ...ignoreBuilderFields([
          'defaultDate',
          'multiple',
          'customOptions',
          'redrawOn',
          'persistent',
          'dbIndex',
          'encrypted',
          'protected',
          'calculateServer',
        ]),
        ...defaultDataFields,
      ],
    },
    {
      key: 'validation',
      components: [
        ...defaultValidationFields,
        ...ignoreBuilderFields(['errorLabel', 'json-validation-json', 'unique']),
      ],
    },
    {
      key: 'api',
      components: [
        ...ignoreBuilderFields(['tags', 'properties']),
        ...defaultApiFields,
      ],
    },
    {
      key: 'logic',
      ignore: true,
    },
    {
      key: 'layout',
      ignore: true,
    },
    {
      key: 'conditional',
      components: defaultConditionalFields,
    },
    tableSettingsTab,
  ]);
};
