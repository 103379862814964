import React from 'react';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';
import { WithStyles } from '@material-ui/core';
import zIndex from '@material-ui/core/styles/zIndex';
import Button, { ButtonVariants } from '#web-components/components/Button';
import IconButton from '#web-components/components/IconButton';
import { CloseIcon } from '#web-components/components/Icons';

import styles from './Modal.styles';

export type ModalProps = Pick<DialogProps, 'scroll' | 'fullScreen' | 'disableEscapeKeyDown'> & {
  isOpen: boolean;
  hasCloseBtn?: boolean;
  onOpenChange: (isOpen: boolean) => void;
  cancelText?: string;
  submitText?: string;
  title?: string | React.ReactNode;
  onSubmit?: () => void;
  onClose?: () => void;
  isPopUp?: boolean;
  noPadding?: boolean;
  disableBackdropClick?: boolean;
  modalzIndex?: number;
  icon?: React.ReactNode;
};

export interface Props extends WithStyles<typeof styles>, ModalProps { }

export default class Modal extends React.Component<Props & WithStyles<typeof styles>> {
  handleClose = () => {
    const { onOpenChange, onClose } = this.props;
    const onCloseModal = onClose || (() => undefined);
    onOpenChange(false);
    onCloseModal();
  };

  handleDialogClose = () => {
    const { disableBackdropClick } = this.props;

    if (disableBackdropClick) {
      return;
    }

    this.handleClose();
  };

  render() {
    const {
      children,
      isOpen,
      title,
      cancelText,
      submitText,
      scroll = 'paper',
      onSubmit,
      hasCloseBtn,
      fullScreen,
      disableEscapeKeyDown,
      classes,
      modalzIndex = zIndex.modal,
      icon,
    } = this.props;

    return (
      <Dialog
        open={isOpen}
        onClose={this.handleDialogClose}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.paper }}
        style={{ zIndex: modalzIndex }}
        scroll={scroll}
        fullScreen={fullScreen}
        disableEscapeKeyDown={disableEscapeKeyDown}
      >
        {icon && <Box className={classes.icon}>{icon}</Box>}
        <DialogTitle
          disableTypography
          classes={{
            root: classes.dialogTitle,
          }}
        >
          {title || ''}
          {hasCloseBtn
            && (
              <IconButton
                aria-label="close"
                onClick={this.handleClose}
                className={classes.closeHeaderBtn}
              >
                <CloseIcon />
              </IconButton>
            )}
        </DialogTitle>

        <DialogContent classes={{ root: classes.dialogContent }}>
          {children}
        </DialogContent>

        {(submitText || cancelText) && (
          <DialogActions
            disableSpacing
            classes={{
              root: classes.dialogActions,
            }}
          >
            {
              submitText && (
                <Button onClick={onSubmit} className={classes.submitBtn}>
                  {submitText}
                </Button>
              )
            }
            {
              cancelText && (
                <Button onClick={this.handleClose} variant={ButtonVariants.secondary}>
                  {cancelText}
                </Button>
              )
            }
          </DialogActions>
        )}
      </Dialog>
    );
  }
}
