import { createStyles, Theme } from '@material-ui/core/styles';
import { h2 } from '#web-components/styles';
import type { ModalProps } from './Modal';

const styles = (theme: Theme) => createStyles({
  paper: {
    padding: ({ noPadding }: ModalProps) => (noPadding ? 0 : theme.spacing(10)),
    paddingRight: ({ noPadding }: ModalProps) => (noPadding ? 0 : theme.spacing(8)),
    maxWidth: ({ fullScreen }: ModalProps) => (fullScreen ? '100%' : theme.spacing(100)),
    width: ({ fullScreen }: ModalProps) => (fullScreen ? '100%' : theme.spacing(100)),
    backgroundColor: theme.colors.layoutBackgroundPrimary,
    textAlign: ({ isPopUp }: ModalProps) => (isPopUp ? 'center' : 'initial'),
  },
  closeHeaderBtn: {
    position: 'absolute',
    right: `${theme.spacing(4)}px`,
    top: `${theme.spacing(4)}px`,
  },
  dialogActions: {
    justifyContent: ({ isPopUp }: ModalProps) => (isPopUp ? 'center' : 'flex-start'),
    marginTop: ({ isPopUp }: ModalProps) => (isPopUp ? theme.spacing(6) : theme.spacing(8)),
    padding: 0,
  },
  dialogTitle: {
    ...h2,
    padding: 0,
  },
  dialogContent: {
    padding: 0,
    paddingRight: ({ noPadding }: ModalProps) => (noPadding ? 0 : theme.spacing(2)),
  },
  submitBtn: {
    marginRight: theme.spacing(3),
  },
  icon: {
    marginBottom: theme.spacing(4),
  },
});

export default styles;
